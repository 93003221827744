@media screen and (min-width:600px) {
    .menu-item-top {
        padding-right: 24px !important;
    }
}
.menu-item-top > div > span {
    float: right;
}
.menu-item > *:first-child {
    min-width: 40px;
}
