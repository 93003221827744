.App {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                              supported by Chrome and Opera */
}
.content-block {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 15px 10px;
}
.content-block::-webkit-scrollbar {
    display: none;
}

/*большая ширина*/
@media screen and (min-width:600px) {
}
/*маленькая ширина*/
@media screen and (max-width:600px) {
    .bottom-space {
        display: block;
        height: 55px;
        width: 100%;
    }
    .content-block {
        padding-bottom: env(safe-area-inset-bottom);
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
.delete-profile-button {
    cursor: pointer;
    width: max-content;
}
.delete-profile-button:hover {
    color: #ff6c6c;
}
.pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 10px;
    transform: scale(1);
    animation: pulse-animation 5s infinite;
}
.colored-button:hover {
    color: #4db34b;
}
.colored-button:active {
    color: #4db34b;
}

@keyframes pulse-animation {
    80% {
        transform: scale(1);
    }
    85% {
        transform: scale(1.25);
    }
    90% {
        transform: scale(1.125);
    }
    95% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}
