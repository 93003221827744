@media screen and (max-width:600px) {
    .item {
        padding-left: unset !important;
        padding-right: unset !important;
    }
    .item.MuiListItem-secondaryAction {
        padding-right: 36px !important;
    }
    .item.button {
        right: 0;
    }
}
.item.slim {
    padding-left: unset !important;
    padding-right: unset !important;
}
.item-text {
    white-space: nowrap;
    overflow: hidden;
    display: flex !important;
    align-items: center;
}
.item-third {
    text-align: right;
    white-space: nowrap;
}
.item.MuiListItem-secondaryAction.slim {
    padding-right: 36px !important;
}
.item.button.slim {
    right: 0;
}
.progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2
}
