.projects-statistics-header {
    display: flex;
    align-items: center;
}
.projects-statistics-list {

}
@media screen and (min-width:600px) {

}
@media screen and (max-width:960px) {
    .projects-statistics-list {
        padding-left: 16px;
    }
}
@media screen and (max-width:600px) {
    .projects-statistics-list {
        padding-left: 8px;
    }
}
