.header-text.button {
    width: 100%;
    height: 36px;
}
.header-text {
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.39998px !important;
    line-height: 17.5px !important;
    text-transform: uppercase;
}
.header-text.item {
    color: #5b5b5b;
    height: 17.5px;
    padding: 10.5px 16px 8px;
}
.header-text.item.center {
    text-align: center;
}
