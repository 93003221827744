.project-item.past {
    opacity: 0.5;
}
.project-item.past + * {
    opacity: 0.5;
}

/*большая ширина*/
@media screen and (min-width:600px) {
    .project-item.child {
        padding-left: 36px !important;
    }
}
/*маленькая ширина*/
@media screen and (max-width:600px) {
    .project-item {
        padding-left: unset !important;
        padding-right: unset !important;
    }
    .project-item.child {
        padding-left: 20px !important;
    }
    .project-item.MuiListItem-secondaryAction {
        padding-right: 36px !important;
    }
    .project-item.button {
        right: 0;
    }
}
