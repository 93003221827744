.field-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.delete-account-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%
}
