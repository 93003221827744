.actions-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (min-width:600px) {
    .actions-panel {
        min-height: 49px
    }
}
.actions-panel > div {
    display: flex;
}
.actions-panel.buttons-block {
    justify-content: flex-start;
}
.actions-panel.bottom {
    position: -webkit-sticky;
    position: sticky;
    z-index: 500;
    bottom: 0;
    box-shadow: 0 5px 0 white, 0 0 5px rgba(0,0,0,0.5);
    background: white;
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    width: 100%;
}
@media screen and (max-width:600px) {
    .actions-panel {
        position: fixed;
        z-index: 500;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: space-around;
        box-shadow: 0 5px 0 white, 0 0 5px rgba(0,0,0,0.5);
        background: white;
        padding-bottom: env(safe-area-inset-bottom);
    }
}
.actions-panel.bottom2 {
    position: fixed;
    z-index: 500;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: space-around;
    box-shadow: 0 5px 0 white, 0 0 5px rgba(0,0,0,0.5);
    background: white;
    padding-bottom: env(safe-area-inset-bottom);
}
.action-panel.pre-bottom2 {
    height: 55px;
    width: 100%;
}
.actions-panel-2{
    width: fit-content !important;
}
.actions-panel-2 > *:not(:last-child){
    margin-right: 4px;
}
