div.action-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*font-size: x-small;*/
}
button.action-button.pick {
    color: #4db34b;
}
button.action-button.red {
    color: #ff6c6c;
}
button.action-button:active {
    color: #4db34b;
}
button.action-button {
    color: #404040;
    font-size: x-small;
}
.action-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.action-button.full-width {
    width: 100%;
}
.action-button.border {
    border-width: 1px;
    border-style: solid;
}
.action-button.empty {
    width: 69px
}
.action-button-2 {
    border-width: 1px;
    border-style: solid;
}
.action-button-2-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.action-button-2-label > svg {
    font-size: 16px;
    margin-right: 4px;
}
